import styles from './NewsBlock.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

export default class NewsBlock extends PureComponent {
  static propTypes = {
    isHead: PropTypes.bool,
    post: PropTypes.object.isRequired,
    router: PropTypes.object
  }

  constructor() {
    super();

    this.state = {
      navigate: false
    };
  }

  openNewsPost() {
    this.setState({navigate: true});
  }

  render() {

    const { post } = this.props;
    const { navigate } = this.state;

    const blockStyle = {
      width: `100%`,
      height: `100%`,
      overflow: `hidden`,
      background: `url('${post.list_image_url}') center / 100% no-repeat`,
      backgroundSize: `cover`,
      position: `absolute`,
    };

    if (navigate) {
      return <Redirect to={`/news/${post.id}`} push={true} />;
    }

    return (
      <div className={styles[`root`]} onClick={this.openNewsPost.bind(this)}>
        <div style={blockStyle}>
          <div className={styles.cover}>
            <div className={styles.title}>
              {post.title}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
