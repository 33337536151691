export default {
  // API URL
  API_URL: api_url(),

  // Cart
  CART_ADD_GAME: `@cart/add_game`,
  CART_REMOVE_GAME: `@cart/remove_game`,
  CART_SET_PAYMENT_METHOD: `@cart/set_payment_method`,

  // Auth
  AUTH_USER_LOADED: `@auth/user_loaded`,
  AUTH_USER_REGISTERED: `@auth/user_registered`,
  AUTH_LOGOUT: `@auth/log_out`,

  // Orders
  ORDERS_HISTORY_LOADED: `@orders/history_loaded`,

  // News
  NEWS_INDEX_LOADED: `@news/index_loaded`
};


function api_url() {
  // if (process.env.NODE_ENV === `development`) {
  //   return `http://localhost:4001`;
  // } else {
  //   return `https://storeapi.crycash.io`;
  // }

  return `https://storeapi.crycash.io`;
}
