import styles from './AccountButton.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import CartButton from '@/components/CartButton';

@translate()
export default class AccountButton extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  render() {
    const { t } = this.props;

    return (
      <div className={styles[`root`]}>
        <Link className={styles[`account-button`]} to='/account'>
          <div className={styles[`button-label`]}>
            {t(`account`)}
          </div>
        </Link>

        <div className={styles.cartbackground}>
          <CartButton/>
        </div>
      </div>
    );
  }
}
