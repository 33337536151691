import 'isomorphic-fetch';

import Constants from '@/constants';

export const GAMES_INDEX_LOADED = `@games/loaded`;

const initialState = {
  items: [],
  headgame: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case GAMES_INDEX_LOADED:
    return Object.assign({}, state, { items: action.items, headgame: action.headgame });
  default:
    return state;
  }
}

export function fetchGames() {
  return async function(dispatch) {
    const res = await fetch(`${Constants.API_URL}/v1/games/index`);

    const status = await res.status;
    if (status === 200) {
      const games_index = await res.json();

      if (games_index[`index`] && games_index[`header`]) {
        dispatch({
          type: GAMES_INDEX_LOADED,
          items: games_index[`index`],
          headgame: games_index[`header`]
        });
      }
    }
  };
}
