import 'isomorphic-fetch';

import Constants from '@/constants';

const initialState = {
  items: [],
  paymentMethod: null,
  order: null
};

function removeGame (arr, game) {
  const index = arr.indexOf(game);

  const res = arr.slice(0,index).concat(arr.slice(index+1));

  return res;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case Constants.CART_ADD_GAME:

    if (state.items.indexOf(action.game) === -1 ) {
      return {
        ...state,
        items: [...state.items, action.game],
      };
    } else {
      return {
        ...state,
        items: [...state.items],
      };
    }

  case Constants.CART_REMOVE_GAME:
    return {
      ...state,
      items: removeGame(state.items, action.game),
    };
  case Constants.CART_SET_PAYMENT_METHOD:
    return {
      ...state,
      paymentMethod: action.method,
    };

  default:
    return state;
  }
}

export function fetchCreateOrder(payment_method, games_ids, email) {
  return async function() {
    const res = await fetch(
      `${Constants.API_URL}/v1/orders/create`,
      {
        method: `POST`,
        headers: {
          Accept: `application/json`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ payment_method, games_ids, email }),
      }
    );

    const res_json = await res.json();

    console.log(res_json); // eslint-disable-line

    if (res_json.pay_url) {
      window.location = res_json.pay_url; // eslint-disable-line
    }
  };
}
