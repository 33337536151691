import 'isomorphic-fetch';

import Constants from '@/constants';

const initialState = {
  current_user: null,
  user_token: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case Constants.AUTH_USER_LOADED:
    return {
      ...state,
      current_user: action.current_user,
    };

  case Constants.AUTH_USER_REGISTERED:
    return {
      ...state,
      current_user: action.current_user,
      user_token:action.user_token,
    };

  default:
    return state;
  }
}

// ###########################

export function fetchLogin(email, password, captchakey) {
  return async function(dispatch) {
    const res = await fetch(
      `${Constants.API_URL}/v1/auth/login`,
      {
        method: `POST`,
        headers: {
          Accept: `application/json`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ email, password, captchakey }),
      }
    );
    const res_json = await res.json();

    const token = res_json.token;
    const user = res_json.user;

    localStorage.setItem(`str-tk`, token); // eslint-disable-line

    dispatch({
      type: Constants.AUTH_USER_LOADED,
      current_user: user
    });
  };
}

export function fetchRegister(email, password) {
  return async function(dispatch) { // eslint-disable-line
    const res = await fetch(
      `${Constants.API_URL}/v1/auth/registration`,
      {
        method: `POST`,
        // credentials: `same-origin`,
        headers: {
          Accept: `application/json`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ email, password })
      }
    );

    const res_json = await res.json();

    console.log(res_json); // eslint-disable-line

    if(!res_json.error) {
      // cookie.set(`token`, res_json.token);
      // cookie.set(`email`, res_json.user.email);
      // window.location.replace("/");
      dispatch({
        type: Constants.AUTH_USER_REGISTERED,
        current_user: res_json.user,
        user_token: res_json.token
      });
    }


  };
}

export function fetchCurrentUser() {
  return async function(dispatch) {

    //const token = localStorage.getItem(`str-tk`); // eslint-disable-line
    const token = `kek`;

    const res = await fetch(
      `${Constants.API_URL}/v1/users/current`,
      {
        method: `GET`,
        headers: {
          'Accept': `application/json`,
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${token}`
        }
      }
    );

    if (res.status == 200) {
      const json = await res.json();

      dispatch({
        type: Constants.AUTH_USER_LOADED,
        current_user: json.user
      });
    }
  };
}

export function fetchChangePassword(new_password, cb) {
  return async function() {

    //const token = localStorage.getItem(`str-tk`); // eslint-disable-line
    const token = `kek`;

    const res = await fetch(
      `${Constants.API_URL}/v1/auth/change_password`,
      {
        method: `POST`,
        headers: {
          'Accept': `application/json`,
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ new_password: new_password })
      }
    );

    if (res.status == 200) {
      const json = await res.json();

      console.log(json); // eslint-disable-line

      cb(true);
    } else {
      cb(false);
    }
  };
}
