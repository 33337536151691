import styles from './Support.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import Footer from '@/components/Footer';
import QABlock from './QABlock';

@translate()
export default class Support extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  render() {
    const { t } = this.props;

    return (
      <div className={styles[`root`]}>
        <div className={styles.header}>
          <div className={styles[`head-title`]}>{t(`support`)}</div>
        </div>
        <div className={styles.blocks}>
          <QABlock title={t(`q1`)} body={t(`a1`)}/>
          <QABlock title={t(`q2`)} body={t(`a2`)}/>
          <QABlock title={t(`q3`)} body={t(`a3`)}/>
          <QABlock title={t(`q4`)} body={t(`a4`)}/>
          <QABlock title={t(`q5`)} body={t(`a5`)}/>
          <QABlock title={t(`q6`)} body={t(`a6`)}/>
          <QABlock title={t(`q7`)} body={t(`a7`)}/>
          <QABlock title={t(`q8`)} body={t(`a8`)}/>
          <QABlock title={t(`q9`)} body={t(`a9`)}/>
        </div>
        <Footer />
      </div>
    );
  }
}
