import styles from './AccountData.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import Footer from '@/components/Footer';
import OrdersHistory from './OrdersHistory';

import SimpleTextField from '@/components/SimpleTextField';
import SimpleButton from '@/components/SimpleButton';
import InfoFooter from '@/components/InfoFooter';

import { fetchChangePassword } from '@/reducers/account';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = () => ({ });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchChangePassword }, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class AccountData extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    current_user: PropTypes.object.isRequired,
    fetchChangePassword: PropTypes.func.isRequired
  }

  constructor() {
    super();

    this.state = {
      password: ``,
      passwordRepeat: ``,
      changed: false
    };
  }

  callChangePassword() {
    console.log(`change password`); // eslint-disable-line

    const { password } = this.state;

    this.props.fetchChangePassword(password, (result) => {
      console.log(`result: ${result}`); // eslint-disable-line

      if (result == true) {
        this.setState({ password: `clean`, passwordRepeat: `clean`, changed: result });
      }
    });
  }

  passwordSet(e) {
    this.setState({ password: e.target.value });
  }

  passwordRepeatSet(e) {
    this.setState({ passwordRepeat: e.target.value });
  }

  render() {
    const { current_user } = this.props;
    const { password, passwordRepeat, changed } = this.state;

    const changeReady = password === passwordRepeat && password != null && password.length > 5;

    return (
      <div className={styles[`root`]}>
        {/* <div className={styles[`head-title`]}>Account Data</div> */}
        <div className={styles[`account-body`]}>
          <p className={styles[`account-info-title`]}>ACCOUNT <span className={styles[`whiteText`]}>INFO</span></p>
          <div className={styles[`account-info`]}>
            <div className={styles[`email-body`]}>
              <div className={styles[`email-title`]}>E-mail</div>
              <div className={styles[`email-data`]}>{current_user.email}</div>
            </div>
            <div className={styles[`password-body`]}>
              <div className={styles[`password-head`]}>
                <div className={styles[`password-title`]}>Change password</div>
                {changed && <div className={styles[`password-changed`]}>Password changed</div>}
              </div>
              <div className={styles[`password-change-content`]}>
                <div className={styles[`password-one-background`]}>
                  <SimpleTextField
                    name="password-1"
                    value={password}
                    type="password"
                    placeholder="New password"
                    onChange={this.passwordSet.bind(this)}
                  />
                </div>
                <div className={styles[`password-two-background`]}>
                  <SimpleTextField
                    name="password-2"
                    value={passwordRepeat}
                    type="password"
                    placeholder="Repeat new password"
                    onChange={this.passwordRepeatSet.bind(this)}
                  />
                </div>
                <div className={styles[`password-button-container`]}>
                  <SimpleButton
                    text={`Change`}
                    isEnabled={changeReady}
                    action={this.callChangePassword.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>

          <p className={styles[`orders-history-title`]}>ORDERS <span className={styles[`whiteText`]}>HISTORY</span></p>
          <div className={styles[`orders-history-head`]}>
          </div>
          {/* <div className={styles[`orders-history`]}>
          </div> */}
          <OrdersHistory />
        </div>
        <InfoFooter />
        <Footer />
      </div>
    );
  }
}
