import styles from './PriceLabel.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class PriceLabel extends PureComponent {
  static propTypes = {
    price: PropTypes.number,
    symbol: PropTypes.string
  }

  render() {
    const { price, symbol } = this.props;

    return (
      <div className={styles[`root`]}>
        <div className={styles[`label`]}>
          {symbol} {price.toFixed(2)}
        </div>
      </div>
    );
  }
}
