import styles from './RegistrationBody.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import validator from '@/utils/validator';
import SimpleTextField from '@/components/SimpleTextField';
import SimpleButton from '@/components/SimpleButton';
import CustomCheckmark from '@/components/CustomCheckmark';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchRegister } from '@/reducers/account';

const mapStateToProps = () => ({  });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchRegister }, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class RegistrationBody extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchRegister: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      emailIsValid: false,
      isCheckmarked: false,
      email: ``,
      password: ``
    };
  }

  register() {
    const { email, password, isCheckmarked } = this.state;

    if(isCheckmarked === true) {
      this.props.fetchRegister(email, password);
      // this.props.fetchRegister(email, password);
    }
  }

  render() {
    const { t } = this.props;
    const { isCheckmarked } = this.state;
    // console.log(this.props.history);
    // console.log(window.location.replace("/"));

    const emailValidators = [{
      message: ``,
      validator: value => validator.isEmail(value),
    }];
    return (
      <div className={styles.root}>
        <div className={styles[`email-background`]}>
          <SimpleTextField
            name="e-mail"
            type="email"
            placeholder="E-MAIL"
            validators={emailValidators}
            successMessage="Valid e-mail format"
            onChange={(e) => {this.setState({email:e.target.value});}}
          />
        </div>

        <div className={styles[`password-background`]}>
          <SimpleTextField
            name="e-mail"
            type="password"
            placeholder="PASSWORD"
            successMessage="Valid e-mail format"
            onChange={(e) => {this.setState({password:e.target.value});}}
          />
        </div>

        <div className={styles[`checkmark-container`]}>
          <CustomCheckmark
            labelText={t(`fimilar_user_ag`)}
            onChange={this.setCheckmark.bind(this)}
          />
        </div>

        <div className={styles[`button-container`]}>
          <SimpleButton
            text={t(`registration_btn`)}
            isEnabled={isCheckmarked}
            action={this.register.bind(this)}
          />
        </div>
      </div>
    );
  }

  setCheckmark(v) {
    this.setState({isCheckmarked: v});
  }
}

// export default translate()(connect(mapStateToProps, mapDispatchToProps)(RegistrationBody));