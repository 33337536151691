import styles from './GamesListItem.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import SimpleButton from '@/components/SimpleButton';
import PriceLabel from '@/components/PriceLabel';

import { addNewGameInCart } from '@/actions/cart';

@translate()
@connect()
export default class GamesListItem extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    game: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  addGame() {
    this.props.dispatch(addNewGameInCart(this.props.game));
  }

  genresString(game) {
    const { genres } = game;
    const { t } = this.props;

    var str = ``;

    for (let i = 0; i < genres.length; i++) {
      if (i == 0) {
        str += `${t(genres[0])}`;
      } else {
        str += `, ${t(genres[i])}`;
      }
    }

    return str;
  }

  render() {
    const { t, game } = this.props;

    return (
      <div className={styles[`root`]}>
        <img className={styles[`game-cover`]} src={game.cover_image_url}/>
        <div className={styles[`bottom-part`]}>
          <div className={styles[`game-title`]}>
            {game.name}
          </div>
          <div className={styles[`availability-title`]}>
          Availability:
            {
              game.available == true ?
                <div className={styles[`availability-green`]}> In stock </div>
                :
                <div className={styles[`availability-red`]}> End of stock </div>
            }
          </div>
          <div className={styles[`genre-title`]}>
          Genre: { this.genresString(game) }
          </div>
          <div className={styles[`price-and-buybutton`]}>
            <PriceLabel price={game.usd_price} symbol="$"/>
            <SimpleButton text={t(`buy`)} action={this.addGame.bind(this)} isEnabled={game.available}/>
          </div>
        </div>
      </div>
    );
  }
}
