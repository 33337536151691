import Constants from '@/constants';

export function addNewGameInCart(game) {
  return (dispatch) => {
    dispatch({
      type: Constants.CART_ADD_GAME,
      game,
    });
  };
}

export function removeGameFromCart(game) {
  return (dispatch) => {
    dispatch({
      type: Constants.CART_REMOVE_GAME,
      game,
    });
  };
}

export function setPaymentMethod(method) {
  return (dispatch) => {
    dispatch({
      type: Constants.CART_SET_PAYMENT_METHOD,
      method,
    });
  };
}
