import styles from './Account.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCurrentUser } from '@/reducers/account';
import cn from 'classnames';

import Footer from '@/components/Footer';
import LoginBody from '@/containers/Account/LoginBody';
import RegistrationBody from '@/containers/Account/RegistrationBody';
import AccountData from '@/containers/Account/AccountData';


const mapStateToProps = (state) => ({ current_user: state.account.current_user });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchCurrentUser }, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class Account extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    current_user: PropTypes.object,
    fetchCurrentUser: PropTypes.func.isRequired
  }

  constructor() {
    super();

    this.state = {
      isLogin: true
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchCurrentUser();
  }

  changeBody = (is_login) => () => {
    this.setState({isLogin: is_login});
  }

  render() {
    const { t, current_user } = this.props;
    const { isLogin } = this.state;

    const loginButtonCN = cn(
      styles[`selector-button`],
      isLogin && styles.active);

    const registrationButtonCN = cn(
      styles[`selector-button`],
      !isLogin && styles.active);

    // const token = localStorage.getItem(`str-tk`);

    return (
      <div>
        { current_user === null ?
          <div className={styles[`root`]}>
            <div className={styles[`login-container`]}>
              <div className={styles[`login-selector`]}>
                <div className={loginButtonCN} onClick={this.changeBody(true)}>
                  <div className={styles[`button-text`]}>
                    {t(`login`)}
                  </div>
                </div>
                <div className={registrationButtonCN} onClick={this.changeBody(false)}>
                  <div className={styles[`button-text`]}>
                    {t(`registration`)}
                  </div>
                </div>
              </div>
              {isLogin == true ? <LoginBody/> : <RegistrationBody />}
            </div>
            <Footer />
          </div>
          :
          <AccountData current_user={current_user}/>
        }
      </div>
    );
  }
}
