import styles from './PaymentButton.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import PriceLabel from '@/components/PriceLabel';
import CustomCheckmark from '@/components/CustomCheckmark';
import SimpleTextField from '@/components/SimpleTextField';
import SimpleButton from '@/components/SimpleButton';
import validator from '@/utils/validator';

const mapStateToProps = (state) => ({
  buyMethod: state.cart.paymentMethod,
  orderGames: state.cart.items
});

@translate()
@connect(mapStateToProps)
export default class PaymentButton extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    buyAction: PropTypes.func,
    orderSum: PropTypes.number,
    buyMethod: PropTypes.string,
    orderGames: PropTypes.array
  }

  constructor(props) {
    super(props);

    this.state = {
      emailIsValid: false,
      isCheckmarked: false,
      email: null,
      isClicked: false
    };
  }

  validEmail(v) {
    this.setState({emailIsValid: v});
  }

  setCheckmark(v) {
    this.setState({isCheckmarked: v});
  }

  setEmailValue(e) {
    this.setState({email: e.target.value});
  }

  buttonAction() {
    const { email } = this.state;

    this.setState({isClicked: true});
    this.props.buyAction(email);
  }

  render() {
    const { t, orderSum, buyMethod, orderGames } = this.props;
    const { emailIsValid, isCheckmarked, isClicked } = this.state;

    const emailValidators = [{
      message: ``,
      validator: value => validator.isEmail(value),
    }];

    const buyEnabled = emailIsValid && isCheckmarked && buyMethod !== null && orderGames.length > 0 && isClicked == false;

    return (
      <div className={styles.root}>
        <div className={styles[`top-total`]}>
          <div className={styles[`total-label`]}>
            {t(`order_summ`)}:
          </div>
          <PriceLabel price={parseFloat(orderSum.toFixed(2))} symbol={`$`}/>
        </div>

        <CustomCheckmark
          labelText={t(`order_accept`)}
          onChange={this.setCheckmark.bind(this)}
        />

        <div className={styles[`bottom-container`]}>
          <SimpleTextField
            name="e-mail"
            type="email"
            placeholder="E-MAIL"
            validators={emailValidators}
            afterValidate={this.validEmail.bind(this)}
            successMessage=""
            onChange={this.setEmailValue.bind(this)}
          />

          <SimpleButton
            text={t(`buy`)}
            action={this.buttonAction.bind(this)}
            isEnabled={buyEnabled}
          />
        </div>
      </div>
    );
  }
}
