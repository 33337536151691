import styles from './QABlock.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export default class QABlock extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string
  }

  constructor() {
    super();

    this.state = {
      showFull: false,
    };
  }

  changeState() {
    this.setState({showFull: !this.state.showFull});
  }

  render() {
    const { title, body } = this.props;
    const { showFull } = this.state;

    const rootCN = cn(
      styles.root,
      showFull && styles.open
    );

    const arrowCN = cn(
      styles.arrow,
      showFull && styles.up
    );

    return (
      <div className={rootCN} onClick={this.changeState.bind(this)}>
        <div className={styles[`title-contantainer`]}>
          <div className={styles.title}>{title}</div>
          <div className={arrowCN}/>
        </div>

        <div className={styles.bodycontainer}>
          <div className={styles.separator}/>
          <div className={styles.bodytext}>{body}</div>
        </div>
      </div>
    );
  }
}
