import styles from './SimpleButton.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class SimpleButton extends PureComponent {
  static propTypes = {
    action: PropTypes.func,
    text: PropTypes.string,
    isEnabled: PropTypes.bool
  }

  static defaultProps = {
    isEnabled: true,
    action: () => {
      // console.log(`SimpleButton was clicked.`);
    },
  }

  render() {
    const { text, isEnabled, action } = this.props;

    const rootCN = cn(
      styles.root,
      isEnabled == false && styles.disabled
    );

    return (
      <div className={rootCN}>
        <div className={styles[`label`]} onClick={action}>
          {text}
        </div>
      </div>
    );
  }
}
