import styles from './InfoFooter.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';

@translate()
export default class InfoFooter extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  render() {
    const { t } = this.props;

    return (
      <div className={styles[`root`]}>
        <div className={styles[`background-image`]}>
          <div className={styles[`info-blocks-container`]}>
            <div className={styles[`info-block`]}>
              <div className={styles[`info-image-1`]} />
              <div className={styles[`info-text`]}>
                {t(`info_footer_t1`)}
              </div>
            </div>
            <div className={styles[`info-block`]}>
              <div className={styles[`info-image-2`]} />
              <div className={styles[`info-text`]}>
                {t(`info_footer_t2`)}
              </div>
            </div>
            <div className={styles[`info-block`]}>
              <div className={styles[`info-image-3`]} />
              <div className={styles[`info-text`]}>
                {t(`info_footer_t3`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
