import styles from './CarouselItem.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { addNewGameInCart } from '@/actions/cart';

@translate()
@connect()
export default class CarouselItem extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    game: PropTypes.object
  }

  addGame() {
    this.props.dispatch(addNewGameInCart(this.props.game));
  }

  render() {
    const { t, game } = this.props;

    // game.rating

    return (
      // <div className={styles[`root`]}>
      <div className={styles[`root`]}>
        <div className={styles[`left-part`]}>
          <div className={styles[`game-title`]}>
            <div className={styles[`game-title-redhalf`]}>HUNT: </div> Showdown
          </div>
          <div className={styles[`game-description`]}>
          Hunt: Showdown from the creators of Crysis is a first-person PvP game with elaborated elements of PvE, in which you will have to compete with opponents in the hunt for trophies. This game, which takes place in the darkest corners of the world.
          </div>
          <div className={styles[`game-genre`]}>
          Genre: Action, Adventure
          </div>
          {/* <div className={styles[`game-platforms`]}>
            <div className={styles[`windows-platform`]} />
            <div className={styles[`macos-platform`]} />
          </div> */}
        </div>
        <div className={styles[`right-part`]}>
          <div className={styles[`rating-container`]}>
            <div className={styles[`rating-container-2`]}>

              <div className={styles[`rating-container-left`]}>

                <div className={styles[`rating-stars`]}>
                  <div className={styles[`rating-star-selected`]} />
                  <div className={styles[`rating-star-selected`]} />
                  <div className={styles[`rating-star-selected`]} />
                  <div className={styles[`rating-star-selected`]} />
                  <div className={styles[`rating-star-unselected`]} />
                </div>

                {/* <div className={styles[`rating-label`]}>

                </div> */}
              </div>

              <div className={styles[`rating-separator`]}/>

              <div className={styles[`rating-container-right`]}>
                {game != null && game.rating}
              </div>

            </div>
          </div>


          <div className={styles[`buy-container`]}>
            <div className={styles[`buy-price`]}>
              {game !== null &&
                <div className={styles[`buy-price `]}>
                  $ {game.usd_price.toFixed(2)}
                </div>
              }
            </div>
            <div className={styles[`buy-button`]} onClick={this.addGame.bind(this)}>
              {t(`buy`)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
