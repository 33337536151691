import styles from './Header.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import AccountButton from '@/components/AccountButton';

@translate()
export default class Header extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  state = {
    sidebar:false
  }

  render() {
    const { t } = this.props;
    const { sidebar } = this.state;

    return (
      <header className={styles[`root`]}>
        <div className={styles[`left-half2`]}>
          <div className={styles[`burger-icon`]} onClick={() => {this.setState({sidebar:!sidebar});}}></div>
        </div>
        <div className={styles[`left-half`]}>
          <Link className={styles[`main-logo`]} to='/'/>

          <div className={styles[`menu`]}>
            <Link className={styles[`menu-button`]} to='/news'>
              <div className={styles[`menu-text`]}>{t(`news`)}</div>
            </Link>
            <Link className={styles[`menu-button`]} to='/support'>
              <div className={styles[`menu-text`]}>{t(`support`)}</div>
            </Link>
            {/* <Link className={styles[`menu-button`]} to='/guarantess'>
              <div className={styles[`menu-text`]}>{t(`guarantees`)}</div>
            </Link> */}
          </div>
        </div>

        <AccountButton />
        {sidebar && (
          <div className={styles[`sidebar`]} onClick={() => {this.setState({sidebar:false});}}>
            <Link className={styles[`sidebar-button`]} to='/news'>
              <div className={styles[`sidebar-text`]}>{t(`news`)}</div>
            </Link>
            <Link className={styles[`sidebar-button`]} to='/support'>
              <div className={styles[`sidebar-text`]}>{t(`support`)}</div>
            </Link>
            <Link className={styles[`sidebar-button`]} to='/account'>
              <div className={styles[`sidebar-text`]}>
                {t(`account`)}
              </div>
            </Link>
          </div>
        )}
      </header>
    );
  }
}
