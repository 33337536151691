import styles from './Footer.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

@translate()
export default class Footer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <footer className={styles[`root`]}>
        <div className={styles[`part`]}>
          <div className={styles[`titletext`]}> {t(`footer_contatus`)} </div>
          {/* <div className={styles[`one-liner`]}>
            <div className={styles[`call-phone-icon`]} />
            <div> +1 (111) 111-11-11 </div>
          </div> */}
          <a className={styles[`one-liner`]} href='mailto:support@crycash.io'>
            <div className={styles[`email-icon`]} />
            <div className={styles[`linktext`]}> support@crycash.io </div>
          </a>
          <div className={styles[`one-liner`]}>
            {/* <a href="//www.free-kassa.ru/">
              <img src="//www.free-kassa.ru/img/fk_btn/17.png" />
            </a> */}
            <a href="//crycash.io/">
              <img className={styles[`crcLogo`]} />
            </a>
          </div>
        </div>
        {/* <div className={styles[`part`]}>
          <a className={styles[`linktext`]} href='https://crycash.io/PP.pdf' target='_blank' rel='noopener noreferrer'> {t(`footer_pp`)} </a>
          <div className={styles[`linktext`]}> Bonus program </div>
          <div className={styles[`linktext`]}> Warranty and support </div>
        </div> */}
        <div className={styles[`part2`]}>
          <a className={styles[`linktext`]} href='https://crycash.io/PP.pdf' target='_blank' rel='noopener noreferrer'> {t(`footer_pp`)} </a>
          <Link className={styles[`linktext`]} to='/news'> {t(`footer_news`)} </Link>
          <Link className={styles[`linktext`]} to='/support'> {t(`footer_support`)} </Link>
        </div>
        <div className={styles[`part`]}>
          <div className={styles[`titletext`]}> {t(`footer_language`)} </div>
          <div className={styles[`one-liner`]}>
            <div className={styles[i18n.language == `en` ? `activetext` : `simpletext`]} onClick={() => i18n.changeLanguage(`en`)}> ENG </div>
            <div className={styles[i18n.language == `ru` ? `activetext` : `simpletext`]} onClick={() => i18n.changeLanguage(`ru`)}> RUS </div>
          </div>
          <div className={styles[`titletext`]}> {t(`footer_followus`)} </div>
          <div className={styles[`one-liner`]}>
            <a className={styles[`facebook-icon`]} href='https://www.facebook.com/crycash.io/'/>
            <a className={styles[`telegram-icon`]} href='https://t.me/crycash'/>
            <a className={styles[`twitter-icon`]} href='https://twitter.com/cry_cash'/>
          </div>
        </div>
      </footer>
    );
  }
}
