import styles from './CartItem.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PriceLabel from '@/components/PriceLabel';
import { removeGameFromCart } from '@/actions/cart';

@connect()
export default class CartItem extends PureComponent {
  static propTypes = {
    game: PropTypes.object,
    dispatch: PropTypes.func.isRequired
  }

  removeGame() {
    const { dispatch, game } = this.props;

    dispatch(removeGameFromCart(game));
  }

  render() {
    const { game } = this.props;

    return (
      <div className={styles[`root`]}>
        <img className={styles[`game-cover`]} src={game.cover_image_url}/>
        <div className={styles[`name-container`]}>
          <div className={styles[`game-name`]}>
            {game.name}
          </div>
          <div className={styles[`game-developer`]}>
            {game.developer}
          </div>
        </div>
        <div className={styles[`price-container`]}>
          <PriceLabel price={game.usd_price} symbol="$"/>
        </div>
        <div className={styles[`close-button`]} onClick={this.removeGame.bind(this)}/>
      </div>
    );
  }
}
