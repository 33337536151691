import 'isomorphic-fetch';

import Constants from '@/constants';

const initialState = {
  items: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case Constants.NEWS_INDEX_LOADED:
    return Object.assign({}, state, { items: action.items });
  default:
    return state;
  }
}

export function fetchNews() {
  return async function(dispatch) {
    const res = await fetch(`${Constants.API_URL}/v1/news/index`);
    const news_index = await res.json();

    // console.log(`games_index /// ${news_index}`); // eslint-disable-line

    dispatch({
      type: Constants.NEWS_INDEX_LOADED,
      items: news_index
    });
  };
}

export function fetchPost(id, cb) {
  return async function() {
    const res = await fetch(
      `${Constants.API_URL}/v1/news/post`,
      {
        method: `POST`,
        headers: {
          Accept: `application/json`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ id }),
      }
    );

    if (res.status == 200) {
      const news_post = await res.json();
      cb(news_post);
    } else {
      cb(null);
    }
  };
}
