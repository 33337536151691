import styles from './News.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Row, Column } from 'react-cellblock';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchNews } from '@/reducers/news';

import NewsBlock from './NewsBlock';
import Footer from '@/components/Footer';

const mapStateToProps = (state) => ({ news: state.news.items });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchNews }, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class News extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    news: PropTypes.array,
    fetchNews: PropTypes.func
  }

  static fetchData(store) {
    return store.dispatch(fetchNews());
  }

  componentDidMount() {
    this.props.fetchNews();
  }

  render() {
    const { t, news } = this.props;

    return (
      <div className={styles[`root`]}>
        <div className={styles[`head-title`]}>{t(`news`)}</div>

        {news.length > 7 &&
          <Grid className={styles[`grid-root`]}>

            <div className={styles.head}>
              <NewsBlock isHead={true} post={news[0]}/>
            </div>

            <Row>
              <Column className={styles[`fullWidth`]} width="1/2">
                <NewsBlock post={news[1]} />
              </Column>
              <Column className={styles[`fullWidth`]} width="1/2">
                <NewsBlock post={news[2]} />
              </Column>
            </Row>
            <Row>
              <Column className={styles[`fullWidth`]} width="1/3">
                <NewsBlock post={news[3]} />
              </Column>
              <Column className={styles[`fullWidth`]} width="2/3">
                <NewsBlock post={news[4]} />
              </Column>
            </Row>
            <Row>
              <Column className={styles[`fullWidth`]} width="1/3">
                <NewsBlock post={news[5]} />
              </Column>
              <Column className={styles[`fullWidth`]} width="1/3">
                <NewsBlock post={news[6]} />
              </Column>
              <Column className={styles[`fullWidth`]} width="1/3">
                <NewsBlock post={news[7]} />
              </Column>
            </Row>
          </Grid>
        }

        <Footer />
      </div>
    );
  }
}
