import styles from './Home.pcss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchGames } from '@/reducers/games';

import Carousel from '@/components/Carousel';
// import AdBanner from '@/components/AdBanner';
import InfoFooter from '@/components/InfoFooter';
import GamesList from '@/components/GamesList';
import Footer from '@/components/Footer';

const mapStateToProps = (state) => ({ games: state.games.items, headgame: state.games.headgame });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchGames }, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
export default class Home extends Component {
  static propTypes = {
    games: PropTypes.array,
    headgame: PropTypes.object,
    fetchGames: PropTypes.func
  }

  static fetchData(store) {
    return store.dispatch(fetchGames());
  }

  componentDidMount() {
    this.props.fetchGames();
  }

  render() {
    const { games, headgame } = this.props;

    return (
      <div className={styles[`root`]}>
        <Carousel headgame={headgame}/>
        {/* <AdBanner /> */}
        <GamesList games={games}/>
        <InfoFooter />
        <Footer/>
      </div>
    );
  }
}
