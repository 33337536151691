import styles from './Cart.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import Footer from '@/components/Footer';

import CartItem from './CartItem';
import PaymentMethod from './PaymentMethod';
import PaymentButton from './PaymentButton';
import EmptyCart from './EmptyCart';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCreateOrder } from '@/reducers/cart';

const mapStateToProps = (state) => ({ games: state.cart.items, paymentMethod: state.cart.paymentMethod });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchCreateOrder }, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class Cart extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    games: PropTypes.array,
    fetchCreateOrder: PropTypes.func,
    paymentMethod: PropTypes.string
  }

  static defaultProps = {
    games: []
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  buyAction(orderEmail) {
    const { games, paymentMethod } = this.props;

    const ids = games.map(game => game.id);

    this.props.fetchCreateOrder(paymentMethod, ids, orderEmail);
  }

  orderSum() {
    const { games } = this.props;

    const list = games.map(a => a.usd_price);

    return list.reduce((a, b) => a + b, 0.0);
  }

  render() {
    const { t, games } = this.props;

    const sum = this.orderSum();

    // console.log(sum);

    return (
      <div className={styles[`root`]}>
        <div className={styles[`container-games`]}>
          <div className={styles[`header-label`]}>
            <div className={styles[`red-text`]}>
              {t(`ordering`)}
            </div>
          </div>

          {games.length === 0 ?
            <EmptyCart />
            :
            games.map((game, i) => {
              return (
                <CartItem key={i} game={game} />
              );
            })
          }
        </div>

        <div className={styles[`container-bottom`]}>

          <div className={styles[`container-buymethod`]}>
            <div className={styles[`header-label`]}>
              <div className={styles[`red-text`]}>
                {t(`step1`)}
              </div>
              <div className={styles[`white-text`]}>
                {t(`select_pyment_method`)}
              </div>
            </div>

            <PaymentMethod />
          </div>

          <div className={styles[`container-puybutton`]}>
            <div className={styles[`header-label`]}>
              <div className={styles[`red-text`]}>
                {t(`step2`)}
              </div>
              <div className={styles[`white-text`]}>
                {t(`confirm_order`)}
              </div>
            </div>

            <PaymentButton orderSum={sum} buyAction={this.buyAction.bind(this)}/>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
