/**
 * @file This file bootstraps all reducers.
 */

export { default as games } from './games';
export { default as users } from './users';
export { default as cart } from './cart';
export { default as account } from './account';
export { default as orders } from './orders';
export { default as news } from './news';
