import styles from './EmptyCart.pcss';
import React, { PureComponent } from 'react';

export default class EmptyCart extends PureComponent {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles[`cart_button`]}/>
        <div className={styles.empty_label}>
        ORDER CART IS EMPTY
        </div>
      </div>
    );
  }
}
