import styles from './CartButton.pcss';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NotificationBadge from 'react-notification-badge';

const mapStateToProps = (state) => ({ items: state.cart.items });

@connect(mapStateToProps)
export default class CartButton extends PureComponent {
  static propTypes = {
    items: PropTypes.array
  }

  render() {

    const badgeStyle = {
      left: `-8px`,
      top: `-8px`,
      padding: `8px 0`,
      borderRadius: `20px`,
      fontSize: `10px`,
      lineHeight: `0.2`,
      width: `20px`
    };

    const containerStyle = {
      position: `relative`,
      width: `none`,
      height: `100%`,
    };

    return (
      <div className={styles.container}>
        <Link className={styles.root} to='/cart'/>

        <NotificationBadge
          count={this.props.items.length}
          style={badgeStyle}
          containerStyle={containerStyle}
          fps={60.0} />
      </div>
    );
  }
}
