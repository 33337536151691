import styles from './SimpleTextField.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class SimpleTextField extends PureComponent {
  static propTypes = {
    // required

    name: PropTypes.string.isRequired,

    // optinal

    type: PropTypes.oneOf([
      `text`,
      `password`,
      `email`,
      `tel`,
      `url`,
    ]),

    defaultValue: PropTypes.string,
    value: PropTypes.string,

    placeholder: PropTypes.string,

    validators: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string.isRequired,
      validator: PropTypes.func.isRequired,
    })),

    validateOnBlur: PropTypes.bool,

    afterValidate: PropTypes.func,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    successMessage: PropTypes.string,
  }

  static defaultProps = {
    type: `text`,
    defaultValue: ``,
    value: ``,
    placeholder: ``,
    validators: [],
    validateOnBlur: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      successMessage: null,

      errorMessage: null,

      isValid: false,

      value: props.defaultValue,
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onChange(e) {
    const value = e.target.value;
    this.setState({ value });

    if (this.props.onChange) {
      this.props.onChange(e, this.props.name);
    }
    if (!this.props.validateOnBlur) {
      this.validate(value);
    }
  }

  onBlur(e) {
    const value = e.target.value;
    if (this.props.validateOnBlur) {
      this.validate(value);
    }

    if (this.props.onBlur) {
      this.props.onBlur(e, this.props.name);
    }
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.prop.onFocus(e, this.props.name);
    }
  }

  validate(value) {
    const errors = this.props.validators.filter((v) => {
      return v.validator && !v.validator(value);
    });

    const err = errors.length > 0 ? errors[0] : null;
    const newState = err
      ? { isValid: false, errorMessage: err.message }
      : { isValid: true, errorMessage: null };

    if (this.props.validators.length > 0) {
      this.setState(newState, () => {
        if (this.props.afterValidate) {
          this.props.afterValidate(this.state.isValid, this.props.name);
        }
      });
    }
  }

  render() {
    const { name, type, value, placeholder } = this.props;
    const { isValid } = this.state;


    const inputCN = cn(
      styles.input,
      isValid == true && styles.success
    );

    return (
      <div className={styles.container}>
        <input
          name={name}
          type={type}
          value={value == `clean` ? `` : this.state.value}
          className={inputCN}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          placeholder={placeholder}
          style={null}
        />
        {/* { this.state.isValid ?
          <span
            className="message success"
          >
            {this.props.successMessage}
          </span>
          :
          <span
            className="message error"
          >
            {this.state.errorMessage}
          </span>
        } */}
      </div>
    );
  }
}
