import styles from './OrdersHistory.pcss';
import React, { PureComponent } from 'react';


// import EmptyOrders from '../EmptyOrders';

export default class OrdersHistory extends PureComponent {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles[`cart_button`]}/>
        <div className={styles.empty_label}>
        HISTORY
        </div>
      </div>
    );
  }
}
