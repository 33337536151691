import styles from './Carousel.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';

import CarouselItem from '@/components/CarouselItem';

@translate()
export default class Carousel extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    headgame: PropTypes.object
  }

  render() {
    const { headgame } = this.props;

    return (
      <div className={styles[`root`]}>
        <CarouselItem game={headgame}/>
      </div>
    );
  }
}
