import styles from './OrderSuccess.pcss';
import React, { PureComponent } from 'react';
import Footer from '@/components/Footer';

export default class OrderSuccess extends PureComponent {
  render() {
    return (
      <div className={styles[`root`]}>
        <div className={styles[`status-container`]}>
          <div className={styles[`ok-icon`]}/>
          <div className={styles[`status-label`]}>
          Payment of the order has taken place successfully, game keys have been sent you by e-mail.
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
