import styles from './PaymentMethod.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

import { connect } from 'react-redux';
import { setPaymentMethod } from '@/actions/cart';

const mapStateToProps = (state) => ({ selectedMethod: state.cart.paymentMethod });

@connect(mapStateToProps)
export default class PaymentMethod extends PureComponent {
  static propTypes = {
    selectedMethod: PropTypes.string,
    dispatch: PropTypes.func.isRequired
  }

  setMethod(method) {
    const { dispatch } = this.props;

    dispatch(setPaymentMethod(method));
  }

  render() {
    const { selectedMethod } = this.props;


    const CRYCASHCN = cn(
      styles[`method-border-button`],
      selectedMethod == `CRC` && styles[`selected`]
    );

    const STEAMPAYCN = cn(
      styles[`method-border-button`],
      selectedMethod == `WAX` && styles[`selected`]
    );

    const BitcoinCN = cn(
      styles[`method-border-button`],
      selectedMethod == `BTC` && styles[`selected`]
    );

    const EthereumCN = cn(
      styles[`method-border-button`],
      selectedMethod == `ETH` && styles[`selected`]
    );

    const BitcoinCashCN = cn(
      styles[`method-border-button`],
      selectedMethod == `BCH` && styles[`selected`]
    );

    const LitecoinCN = cn(
      styles[`method-border-button`],
      selectedMethod == `LTC` && styles[`selected`]
    );

    return (
      <div className={styles[`root`]}>
        <div className={CRYCASHCN} onClick={() => this.setMethod(`CRC`)}>
          <div className={styles[`crc-icon`]}/>
          <div className={styles[`method-name`]}>
          CRYCASH
          </div>
        </div>
        <div className={STEAMPAYCN} onClick={() => this.setMethod(`WAX`)}>
          <div className={styles[`wax-logo`]}/>
          <div className={styles[`method-name`]}>
          WAX
          </div>
        </div>
        <div className={BitcoinCN} onClick={() => this.setMethod(`BTC`)}>
          <div className={styles[`bitcoin-icon`]}/>
          <div className={styles[`method-name`]}>
          Bitcoin
          </div>
        </div>
        <div className={EthereumCN} onClick={() => this.setMethod(`ETH`)}>
          <div className={styles[`ethereum-icon`]}/>
          <div className={styles[`method-name`]}>
          Ethereum
          </div>
        </div>
        <div className={BitcoinCashCN} onClick={() => this.setMethod(`BCH`)}>
          <div className={styles[`bch-icon`]}/>
          <div className={styles[`method-name`]}>
          Bitcoin Cash
          </div>
        </div>
        <div className={LitecoinCN} onClick={() => this.setMethod(`LTC`)}>
          <div className={styles[`litecoin-icon`]}/>
          <div className={styles[`method-name`]}>
          Litecoin
          </div>
        </div>
      </div>
    );
  }
}
