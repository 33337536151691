import styles from './CustomCheckmark.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class CustomCheckmark extends PureComponent {
  static propTypes = {
    labelText: PropTypes.string,
    onChange: PropTypes.func,
    uniqId: PropTypes.string
  }

  static defaultProps = {
    labelText: `test`,
    uniqId: `checkmark-id`
  };

  constructor() {
    super();

    this.state = {
      isChecked: false,
      isDisabled: false
    };
  }

  changeChecked = () => {
    const v = !this.state.isChecked;

    this.props.onChange(v);
    this.setState({isChecked: v});
  }

  randomId() {
    `crystore-` + Math.random().toString(36).substring(7);
  }

  render() {
    const { labelText, uniqId } = this.props;
    const { isChecked, isDisabled } = this.state;

    const className = cn(
      styles[`rm-checkbox`],
      isChecked && styles.checked,
      !isDisabled && styles.enabled,
      isDisabled && styles.disabled);

    return (
      <div className={className}>
        <input
          id={uniqId}
          type="checkbox"
          onChange={this.changeChecked}
          checked={this.state.isChecked}
          disabled={this.state.isDisabled} />
        <label htmlFor={uniqId}>{labelText}</label>
      </div>
    );
  }
}
