import 'isomorphic-fetch';

import Constants from '@/constants';

const initialState = {
  orders: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case Constants.ORDERS_HISTORY_LOADED:
    return {
      ...state,
      orders: action.orders,
    };
    
  default:
    return state;
  }
}

export function fetchOrdersHistory() {
  return async function(dispatch) {
    const res = await fetch(`${Constants.API_URL}/v1/games/index`);
    const orders = await res.json();

    dispatch({
      type: Constants.ORDERS_HISTORY_LOADED,
      orders: orders
    });
  };
}
