import styles from './OrderFailed.pcss';
import React, { PureComponent } from 'react';
import Footer from '@/components/Footer';

export default class OrderFailed extends PureComponent {
  render() {
    return (
      <div className={styles[`root`]}>
        <div className={styles[`status-container`]}>
          <div className={styles[`fail-icon`]}/>
          <div className={styles[`status-label`]}>
          Payment of the order has taken place failed.
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
