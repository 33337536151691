/**
 * @file Client app root.
 */

import { CookiesProvider } from 'react-cookie';
import styles from './App.pcss';
import Header from '@/components/Header';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

export default class App extends PureComponent {
  static propTypes = {
    route: PropTypes.object.isRequired,
  }

  render() {
    return (
      <CookiesProvider>
        <div className={styles.root}>
          <Header/>
          {renderRoutes(this.props.route.routes)}
        </div>
      </CookiesProvider>
    );
  }
}
