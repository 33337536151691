import styles from './LoginBody.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import validator from '@/utils/validator';
import ReCAPTCHA from 'react-google-recaptcha';

import SimpleTextField from '@/components/SimpleTextField';
import SimpleButton from '@/components/SimpleButton';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchLogin } from '@/reducers/account';

const mapStateToProps = () => ({ });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchLogin }, dispatch);

@translate()
@connect(mapStateToProps, mapDispatchToProps)
export default class LoginBody extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchLogin: PropTypes.func.isRequired
  }

  constructor() {
    super();

    this.state = ({
      captchakey: null,
      emailIsValid: false,
      email: null,
      password: null
    });
  }

  recaptchaCallback(responsekey) {
    this.setState({captchakey: responsekey});
  }

  validEmail(v) {
    this.setState({emailIsValid: v});
  }

  setEmailValue(e) {
    // console.log(e.target.value);

    this.setState({email: e.target.value});
  }

  setPasswordValue(e) {
    // console.log(e.target.value);

    this.setState({password: e.target.value});
  }

  callLogin() {
    const { email, password, captchakey } = this.state;

    // this.props.fetchLogin(email, password, captchakey);

    this.props.fetchLogin(email, password, captchakey);
  }

  render() {
    const { t } = this.props;

    const emailValidators = [{
      message: ``,
      validator: value => validator.isEmail(value),
    }];

    const validForm = this.state.emailIsValid && this.state.captchakey != null;

    return (
      <div className={styles.root}>
        <div className={styles[`email-background`]}>
          <SimpleTextField
            name="e-mail"
            type="email"
            placeholder="E-MAIL"
            validators={emailValidators}
            afterValidate={this.validEmail.bind(this)}
            onChange={this.setEmailValue.bind(this)}
          />
        </div>

        <div className={styles[`password-background`]}>
          <SimpleTextField
            name="password"
            type="password"
            placeholder="PASSWORD"
            onChange={this.setPasswordValue.bind(this)}
          />
        </div>

        <div className={styles[`recapcha`]}>
          <ReCAPTCHA
            sitekey="6LdsR1sUAAAAAEUuX96dlq_Abtz088kXZsnjTCeB"
            theme="dark"
            onChange={this.recaptchaCallback.bind(this)}
          />
        </div>

        <div className={styles[`button-container`]}>
          <SimpleButton
            text={t(`sign_in`)}
            isEnabled={validForm}
            action={this.callLogin.bind(this)}
          />
        </div>
      </div>
    );
  }
}
