import styles from './GamesList.pcss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';

// import GamesListNav from '@/components/GamesListNav';
import GamesListItem from '@/components/GamesListItem';

@translate()
export default class GamesList extends PureComponent {
  static propTypes = {
    games: PropTypes.array
  }

  render() {
    const { games } = this.props;

    return (
      <div className={styles[`root`]}>
        <div className={styles[`title`]}>
          <div className={styles[`title-redhalf`]}>GAMES</div>
          <div> LIST </div>
        </div>
        {/* <div className={styles[`title`]}>
         <GamesListNav />
        </div> */}
        <div className={styles[`list`]}>
          {
            games.map(game => {
              return (
                <GamesListItem key={game.id} game={game} />
              );
            })
          }
        </div>
      </div>
    );
  }
}
