import styles from './NewsPost.pcss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Footer from '@/components/Footer';
import Safe from 'react-safe';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPost } from '@/reducers/news';

const mapStateToProps = (state) => ({ news: state.news.items });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchPost }, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
export default class NewsPost extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    fetchPost: PropTypes.func
  }

  constructor() {
    super();

    this.state = {
      post: null
    };
  }

  static fetchData(store) {
    if (this.props !== undefined) {
      const { id } = this.props.match.params;

      return store.dispatch(fetchPost(id, (post) => {
        this.setState({ post });
      }));
    }
  }

  componentDidMount() {
    if (this.props !== undefined) {
      const { id } = this.props.match.params;

      this.props.fetchPost(id, (post) => {
        this.setState({ post });
      });
    }
  }

  render() {
    const { post } = this.state;

    const blockStyle = {
      width: `100%`,
      height: `100%`,
      overflow: `hidden`,
      background: `url('${post && post.list_image_url}') center / 100% no-repeat`,
      backgroundSize: `cover`,
      display: `flex`,
    };

    return (
      <div className={styles[`root`]}>
        <div className={styles.body}>
          {post &&
            <div style={{display: `flex`, flexDirection: `column`, alignItems: `center`}}>
              <div className={styles.posthead}>
                <div style={blockStyle}>
                  <div className={styles.postheadcover}>
                    <div className={styles[`head-title`]}>
                      {post.title}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles[`post-body`]}>
                <Safe.div>
                  {post.html_body}
                </Safe.div>
              </div>
            </div>
          }
        </div>
        <Footer />
      </div>
    );
  }
}
