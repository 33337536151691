/**
 * @file Route definitions for React router.
 */

// import About from '@/containers/About';
// import List from '@/containers/List';
// import Guarantess from '@/containers/Guarantess';

import App from '@/containers/App';
import Home from '@/containers/Home';

import News from '@/containers/News';
import NewsPost from '@/containers/News/NewsPost';
import Support from '@/containers/Support';
import Account from '@/containers/Account';
import Cart from '@/containers/Cart';
import OrderSuccess from '@/containers/OrderSuccess';
import OrderFailed from '@/containers/OrderFailed';

import NotFound from '@/containers/NotFound';

export default [{
  component: App,
  routes: [{
    path: `/`,
    exact: true,
    component: Home
  },{
    path: `/news/:id`,
    component: NewsPost
  },{
    path: `/news`,
    component: News
  },{
    path: `/support`,
    component: Support
  },{
    path: `/account`,
    component: Account
  },{
    path: `/cart`,
    component: Cart
  },{
    path: `/fk-success`,
    component: OrderSuccess
  },{
    path: `/fk-fail`,
    component: OrderFailed
  }, {
    path: `*`,
    component: NotFound
  }]
}];
